import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './index.scss';
import { bool, object, string } from 'prop-types';
import { Stack } from '@mui/material';

const AccordionSection = (props) => {
  const { children, title, subtext = 'available', subtextColour = '#000', expanded = false, accordionDetailsProps = {}, accordionProps = {}, className = '', ...other } = props;

  return (
    <Box className={`accordion_section ${className}`}  {...other}>
      <Accordion
        defaultExpanded={expanded}
        {...accordionProps}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{
            fontSize: {
              xs: '1.5rem',
              md: '2rem',
            }
          }} />}
          aria-controls="panel1bh-content"
          sx={{ minHeight: '50px', py: 2, mt: '0 !important' }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              width: '100%',
            }}
          >
            <Typography
              className='contest_accordion_title'
              variant='h4'
              sx={{
                pr: 1,
              }}
            >
              { title }
            </Typography>

            <Typography
              component='div'
              sx={{
                ml: 'auto',
                fontSize: '0.75rem',
                textTransform: 'uppercase',
                px: 0.5,
                color: subtextColour,
              }}
            >
              { subtext }
            </Typography>
          </Stack>

          <Divider component={'div'} className='contest_accordion_divider' />
        </AccordionSummary>

        <AccordionDetails
          sx={{}}
          {...accordionDetailsProps}
        >
          <div>
            { children }
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

AccordionSection.propTypes = {
  title: string.isRequired,
  className: string,
  accordionProps: object,
  accordionDetailsProps: object,
  expanded: bool,
}

export default AccordionSection