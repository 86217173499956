import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';
import ContestArt from '../../../shared/img/ContestArt.png';

import Wysiwyg from "../../Wysiwyg";

import styles from './styles.module.scss';
import { DialogContestTitle } from "../DialogContestTitle";
import { DialogContestFooter } from "../DialogContestFooter";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../utils/translate";
import { useFetchAchievements } from "../../../hooks/useFetchAchievements";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestInfoDialogBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { retailer } = useContext(RetailerContext);
    const { code: languageCode } = useSelector(state => state.language);

    const contests = useSelector(state => state.contests);

    const contest = contests?.find(item => item?.id) || null;

    let title;
    let subtitle;
    let featuredImage;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        featuredImage = `${process.env.REACT_APP_API_URL}/assets/${translatedContest?.featured_image}?key=contest-feature-image` || ContestArt;
        title = translatedContest?.heading || t('enterContestInfoSubtitle');
        subtitle = translatedContest?.subheading || t('enterContestDescription');
    }

    const filter = {
            _and: [
                {
                    status: {
                        '_eq': 'published'
                    }
                },
                ...(contest ? [
                    {
                        contests: {
                            contests_id: {
                                '_eq': contest.id
                            }
                        }
                    }
                ] : []),
            ]
        };
    
    const { data: achievements } = useFetchAchievements(filter);

    let modalToOpen = null;
    let buttonText = null;

    for (let i = 0; i < achievements?.length; i++) {
        const achievement = achievements[i];

        // if achievement has not been completed, then set it as next achievement
        if (((achievement.points / achievement.target) * 100) < 100) {
            if (achievement.type === 'contest-form') {
                modalToOpen = 'contest-form';
                buttonText = t('enterContest');
                break;
            }

            if (achievement.type === 'watch-video') {
                modalToOpen = 'my-achievements';
                buttonText = t('thirdChanceToWin');
                continue;
            }
        }
    }

    const handleClose = (componentToOpen = null) => {
        onClose(componentToOpen);
    };

    if (!modalToOpen) {
        return null;
    }

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={() => handleClose('promotion')}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        mt: 2,
                        lineHeight: '1.25rem',
                        '.wysiwyg p': {
                            m: 0,
                        }
                    }}
                >
                    <Wysiwyg>
                        {title}
                    </Wysiwyg>
                </Typography>

                <Typography
                    variant={'h2'}
                    component={'div'}
                    sx={{
                        color: retailer.brand_color_2,
                        fontWeight: 'bold',
                        fontSize: '1.26rem',
                        textAlign: 'center',
                        mb: 2,
                        mt: "0.5rem",
                        lineHeight: '1rem',
                        '.wysiwyg p': {
                            m: 0,
                        }
                    }}
                >
                    <Wysiwyg>
                        {subtitle}
                    </Wysiwyg>
                </Typography>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                    }}
                >
                    <img
                        src={featuredImage}
                        alt={''}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>

                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        {
                            !!modalToOpen &&
                            <Button
                                variant="contained"
                                color="success"
                                sx={{
                                    borderRadius: '0',
                                    backgroundColor: '#008F01',
                                    fontFamily: retailer.button_font,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    mb: 1,
                                }}
                                onClick={() => handleClose(modalToOpen)}
                            >
                                {buttonText}
                            </Button>
                        }

                        <DialogContestFooter
                            rules={true}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
export const DialogContestInfo = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box className='no-print'>
            <ContestInfoDialogBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
};