import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import ContestArt from '../../../shared/img/Bitmap.png';

import Wysiwyg from "../../Wysiwyg";

import styles from './styles.module.scss';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DialogContestFooter } from "../DialogContestFooter";
import { DialogContestTitle } from "../DialogContestTitle";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestInformationDialogBox = ({ open, onClose, title, subtitle }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { retailer } = useContext(RetailerContext);

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        '& strong': {
                            color: retailer.brand_color_2,
                        },
                        lineHeight: '1rem',
                        mt: 4
                    }}
                >
                    <Wysiwyg
                        component={'span'}
                    >
                        {title}
                    </Wysiwyg>
                </Typography>

                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: '0',
                                backgroundColor: '#008F01',
                                fontFamily: retailer.button_font,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 3,
                                mt: 5
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                            >
                            {t('close')}
                        </Button>

                        <DialogContestFooter
                            rules={false}
                            handleClose={handleClose}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
export const DialogContestInformation = ({ defaultOpen = false, onClose, title, subtitle }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Box className='no-print'>
            <ContestInformationDialogBox
                open={defaultOpen}
                onClose={handleClose}
                title={title}
                subtitle={subtitle}
            />
        </Box>
    )
};