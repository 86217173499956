import userSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { fetcher } from '../utils/fetcher';
import { useGetShortCode } from './useGetShortCode';
import { useSelector } from 'react-redux';
export const useFetchPoints = () => {
    const shortCode = useGetShortCode();

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    const fields = [
        '*',
    ];

    const deep = {};

    const filter = {
        _and: [
            {
                short_code: {
                    '_eq': shortCode
                },
                contest: {
                    '_eq': contest?.id
                },
            },
        ]
    };

    const { data: defaultData } = userSWR({
        url: 'points',
        deep,
        limit: -1,
        fields,
        filter,
        aggregate: {
            sum: 'points',
        }
    }, fetcher);

    const { data, trigger } = useSWRMutation({
        url: 'points',
        deep,
        limit: -1,
        fields,
        filter,
        aggregate: {
            sum: 'points',
        }
    }, fetcher);

    const [totalPoints] = data || defaultData || []; 

    return {
        totalPoints: totalPoints?.sum?.points || '-',
        trigger,
    };
};