import { useContext, useEffect, useState } from "react";

import { Box } from "@mui/material";

import { DialogContestNag } from "../DialogContestNag";
import { DialogContestInfo } from "../DialogContestInfo";
import { DialogContestForm } from "../DialogContestForm";
import { DialogContestSuccess } from "../DialogContestSuccess";

import api from "../../../api";

import { useGetShortCode } from "../../../hooks/useGetShortCode";

import notify from "../../Notification/helper";
import { DialogRestoreProgress } from "../DialogRestoreProgress";
import { DialogSocialShare } from "../DialogSocialShare";
import { AwardsContext } from "../../../context/AwardsContext";
import { DialogMyAchievements } from "../DialogMyAchievements";
import DialogAward from "../DialogAward";
import { DialogContestInformation } from "../DialogContestInformation";
import { useTranslation } from "react-i18next";
import { DialogWatchVideo } from "../DialogWatchVideo";
import { DialogContestRegistrationSuccess } from "../DialogContestRegistrationSuccess";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../utils/translate";
import { useFetchAchievements } from "../../../hooks/useFetchAchievements";
import { pushToGoogleAnalytics } from "../../../utils/google-analytics";

export const ModalContainer = () => {
    const { t } = useTranslation(['gamification']);

    const shortCode = useGetShortCode();
    const [enteredContest, setEnteredContest] = useState(false);
    const [openContestNag, setOpenContestNag] = useState(false);
    const [openContestInfo, setOpenContestInfo] = useState(false);

    const { modalToOpen, setModalToOpen, setModalToOpenWithItem, showPointsAnimation } = useContext(AwardsContext);

    const { code: languageCode } = useSelector(state => state.language);

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    let closingMessage;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        closingMessage = translatedContest?.closing_message || '';
    }

    const filter = {
        _and: [
            {
                status: {
                    '_eq': 'published'
                }
            },
            ...(contest ? [
                {
                    contests: {
                        contests_id: {
                            '_eq': contest.id
                        }
                    }
                }
            ] : []),
        ]
    };

    const { data } = useFetchAchievements(filter);

    const achievements = data?.map(achievement => {
        const translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });
        const imageUrl = achievement.image ?
            `${process.env.REACT_APP_API_URL}/assets/${achievement.image}?key=achievement-image` :
            TrophyImage;

        return {
            id: achievement.id,
            buttonText: translatedAchievement.action_text,
            title: translatedAchievement.title,
            type: achievement.type,
            modalToOpen: achievement.type,
            completed: false,
            points: achievement.points,
            target: achievement.target,
            imageUrl,
            message: translatedAchievement.description,
            achievement,
        }
    }) || [];

    // TODO make pure function
    const getContestJunctionItems = async () => {
        try {
            const contestJunctionItems = await api.getJunctionItems('contests_short_codes', {
                filter: {
                    _and: [
                        {
                            contests_id: {
                                _eq: contest.id,
                            },
                        },
                        {
                            short_codes_id: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            if (contestJunctionItems?.length) {
                setEnteredContest(true);
            } else {
                setEnteredContest(false);

                const pageViews = Number(sessionStorage.getItem('pageViewsCount'));

                if (pageViews < 0) {
                    setOpenContestInfo(true);
                }
            }
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    const onClose = (component = null, item) => {
        let componentToOpen = component;

        // if component is contest form, then check if user already entered
        if (component === 'contest-form') {
            // if user has already entered, then open contest form success
            if (enteredContest) {
                componentToOpen = 'contest-form-success';
            }
        }

        if (item) {
            setModalToOpenWithItem(componentToOpen, item);
        } else {
            setModalToOpen(componentToOpen);
        }
    };

    useEffect(async () => {
        if (contest !== null) {
            await getContestJunctionItems();
        }
    }, []);

    useEffect(async () => {
        if (contest !== null) {
            const pageViews = Number(sessionStorage.getItem('pageViewsCount'));

            if (pageViews >= 10 && !enteredContest) {
                setOpenContestNag(true);
            }
        }
    });

    useEffect(() => {
        if (openContestNag) {
            pushToGoogleAnalytics({
                event: 'modalOpen',
                type: 'contest-nag',
                name: 'Canadian Beef Contest',
            });
        }

        if (openContestInfo) {
            pushToGoogleAnalytics({
                event: 'modalOpen',
                type: 'contest-info',
                name: 'Canadian Beef Contest',
            });
        }
    }, [openContestNag, openContestInfo]);

    if (contest === null) {
        onClose();
        return null;
    }

    return (
        <Box>
            <DialogContestInfo
                defaultOpen={modalToOpen === 'contest-nag' || openContestNag}
                onClose={
                    componentToOpen => {
                        sessionStorage.setItem('pageViewsCount', 0);
                        setOpenContestNag(false);

                        onClose(componentToOpen);
                    }
                }
            />

            {
                achievements.map(item => {
                    if (item.type === 'contest-form') {
                        return (
                            <DialogContestInfo
                                key={item.id}
                                defaultOpen={modalToOpen === 'contest-info' || openContestInfo}
                                onClose={
                                    componentToOpen => {
                                        // if component is award, then animate points
                                        sessionStorage.setItem('pageViewsCount', 0);
                                        setOpenContestInfo(false);

                                        onClose(componentToOpen, item.achievement);
                                    }
                                }
                            />
                        )
                    }

                    if (item.type === 'watch-video') {
                        return (
                            <DialogWatchVideo
                                key={item.id}
                                defaultOpen={modalToOpen === item.id}
                                onClose={onClose}
                                achievementId={item.id}
                                collection={'watch-video'}
                            />
                        )
                    }
                })
            }

            <DialogContestForm
                key={modalToOpen}
                defaultOpen={modalToOpen === 'contest-form'}
                onClose={onClose}
            />

            <DialogContestInformation
                defaultOpen={modalToOpen === 'promotion'}
                onClose={onClose}
                title={closingMessage}
                subtitle={t('enterContestSuccessTitle')}
            />

            <DialogContestSuccess
                defaultOpen={modalToOpen === 'contest-form-success'}
                onClose={onClose}
            />

            <DialogRestoreProgress
                defaultOpen={modalToOpen === 'restore-progress'}
                onClose={onClose}
            />

            <DialogSocialShare
                defaultOpen={modalToOpen === 'social-share'}
                onClose={onClose}
            />

            <DialogMyAchievements
                defaultOpen={modalToOpen === 'my-achievements'}
                onClose={onClose}
            />

            <DialogAward
                defaultOpen={modalToOpen === 'award'}
                onClose={(componentToOpen, item) => {
                        // if component is award, then animate points
                        showPointsAnimation();

                        onClose(componentToOpen, item);
                    }
                }
            />
        </Box>
    )
}